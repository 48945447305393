@tailwind base;
@tailwind components;
@tailwind utilities;

.accordion {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.accordion__button {
    background-color: white;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    border-radius: 5px;
}

.accordion__button:hover {
    background-color: #66a0ff;
}

.accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
}

[hidden] {
    display: none;
}

.accordion__panel {
    padding: 20px;
    animation: fadein 0.35s ease-in;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.rs-form-group .rs-input {
    width: 300px;
}

.mobile-menu-button {
    display: none !important;
    padding: 0 !important;
    outline: none !important;
    background: transparent !important;
}

.mobile-menu-button.rs-btn-icon.rs-btn>.rs-icon  {
    font-size: 22px;
}

.close-button  {
    position: absolute !important;
    padding: 20px 24px !important;
}

textarea {
    resize: none !important;
}

main {
    width: calc(100% - 260px);
}

.delete-mobile-button {
    position: absolute !important;
    outline: none !important;
    background: transparent !important;
}

.custom-overflow-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    flex: 1;
    text-overflow: ellipsis;
}

.rs-panel {
    border: none !important;
    border-radius: 0px !important;
    margin-bottom: 12px;
}

.rs-panel-header {
    padding: 8px 0 !important;
    border-bottom: 2px solid #e4e7eb !important;
    font-size: 14px !important;
    color: #575757 !important;
}

.rs-panel-in .rs-panel-header {
    border-bottom: 2px solid #3c82f6 !important;
}   

.rs-panel-collapsible>.rs-panel-header>.rs-icon {
    top: 10px !important;
    right: 12px !important;
    left: auto !important;
}

.rs-panel-body {
    padding: 20px 0 !important;
}

.rs-panel-group .rs-panel+.rs-panel:before {
    display: none !important;
}

.order-panel .rs-panel-header {
    padding: 8px 0 !important;
    border-bottom: none !important;
    font-size: 14px !important;
    color: #575757 !important;
}

.order-panel .rs-panel-body {
    padding: 0 !important;
}

.order-panel .rs-panel {
    margin-bottom: 0 !important;
}

.order-panel .rs-panel-header * {
    pointer-events: none;
}

.order-panel .rs-panel-header {
    font-weight: bold;
}

.phone-number {
    color: #575757;
}

@media only screen and (max-width: 1024px) {
    .rs-form.rs-form-horizontal  {
        padding: 0 24px 24px;
    }
    .rs-form-control.rs-form-control-wrapper {
        width: 100% !important;
    }
    .rs-form-horizontal .rs-form-group .rs-form-control-label {
        width: 100% !important;
        text-align: left !important;
        padding-bottom: 0;
    }
    .rs-form-control-wrapper>.rs-input, .rs-form-group .rs-input {
        width: 100% !important;
    }
    .rs-form-control-label[for="saveButton"] {
        display: none !important;
    }
    .rs-modal-sm {
        width: calc(100% - 24px) !important;
        margin-bottom: 12px !important;
    }
    .rs-modal-content .rs-form.rs-form-horizontal {
        padding: 0 !important;
    }
    .mobile-menu-button {
        display: block !important;
    }
    main {
        width: 100%;
    }
    .accordion__panel {
        padding-bottom: 8px !important;
    }
}


